import { Col, Row } from 'antd';
import { React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import images from '~/assets/images/images';
import PrimaryButton from '~/components/Layout/components/Button';
import PublicBig from '~/components/Layout/components/PublicBig';
import PublicSmall from '~/components/Layout/components/PublicSmall';
import Slider from '~/components/Layout/components/Slider/index.js';
import './Homepage.sass';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const time = '0:00 AM';
const publications = [
    {
        name: 'Blockchain for Education: Verification and Management of Lifelong Learning Data',
        img: images.pub1,
        public_time: '2022', //can sua dung thoi gian
        description:
            'In recent years, blockchain technology has been applied in the educational domain because of its salient advantages, i.e., transparency, decentralization, and immutability. Available systems...',
    },
    {
        name: 'nQSV-Net: a novel queuing search variant for global space search and workload modeling',
        img: images.pub2,
        public_time: '2021',
        description:
            '  In this paper, we present our research that goes through two steps: (1) using meta-heuristic ...',
    },
    {
        name: 'BML: A Data Mapping Language for Blockchain Platforms',
        img: images.pub3,
        public_time: '2020',
        description:
            'Currently, all blockchain-based applications conduct two primary operations, i.e., writing data on ...',
    },
    {
        name: 'Challenges and Strategies for Developing Decentralized Applications..',
        img: images.pub4,
        public_time: '2019',
        description: 'This paper reveals challenges and strategies of data models, deployment scenarios, business...',
    },
];

const Homepage = () => {
    const { t } = useTranslation();

    const mainMembers = [
        {
            name: 'nguyenbinhminh',
            avatar: images.NBM_teacher,
            position: 'header',
            description: 'teacher',
        },
        {
            name: 'daothanhchung',
            avatar: images.DTC_teacher,
            position: 'researcher',
            description: 'teacher',
        },
        {
            name: 'dobalam',
            avatar: images.DBL_teacher,
            position: 'researcher',
            description: 'teacher',
        },
        {
            name: 'dinhhoangnam',
            avatar: images.dhn,
            position: 'blockchain_developer',
            description: 'engineer',
        },
    ];

    const nav = document.querySelectorAll('.header__nav-item');

    window.addEventListener('scroll', () => {
        nav[0].classList.remove('header__nav-item--active');
        let current = '';
        const sec = document.querySelectorAll('.section');
        if (sec.length !== 0) {
            sec.forEach((section) => {
                const secTop = section.offsetTop;
                const secHeight = section.clientHeight;
                const pyos = window.pageYOffset;
                if (pyos >= secTop - secHeight / 2) {
                    current = section.getAttribute('id');
                }
            });
        }

        nav.forEach((item) => {
            item.classList.remove('header__nav-item--active');
            console.log(current);
            if (item.classList.contains(current)) {
                item.classList.add('header__nav-item--active');
            }
        });
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ width: '100%', margin: '0 auto', backgroundColor: '#FFFFFF', position: 'relative' }}>
            <section className="section" style={{ width: '100%', height: '90px' }}></section>
            {/* <><section className="section" id="solutions_a"> */}
            <section className="section" id="introduction_a">
                <div className="introduction">
                    {/* <img src={images.background} alt="introduction" className="introduction__image"></img> */}
                    <div className="introduction__image">
                        <Slider></Slider>
                    </div>
                    <div className="introduction__background">
                        <div className="introduction__background-text">
                            <span className="introduction__background-text-heading">
                                {t('bkc.homepage.intro.heading')}
                            </span>
                            <span className="introduction__background-text-detail">
                                {t('bkc.homepage.intro.detail')}
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="solutions_a">
                <div className="solutions">
                    <h2 className="solutions__heading">{t('bkc.homepage.solution.heading')}</h2>
                    <div className="solutions__list">
                        <div className="row medium-gutter">
                            {/* <Col span={12}> */}
                            <Row gutter={[32, 16]} justify="center" align="top">
                                <Col>
                                    <div className="solutions__list-item solution-b4e">
                                        <div className="solutions__list-item-top">
                                            <h3 className="solutions__list-item-top-heading">
                                                {t('bkc.homepage.solution.bsign')}
                                            </h3>
                                            <span className="solutions__list-item-top-descriptions">
                                                {t('bkc.homepage.solution.bsign-description')}
                                            </span>
                                            <Link to="/bsign" className="solutions__list-item-top-text">
                                                {' '}
                                                {t('bkc.homepage.solution.more')} &rarr;
                                            </Link>
                                        </div>
                                        <img src={images.bsign} alt="b4e" className="solutions__list-item-img"></img>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="solutions__list-item solution-b4e">
                                        <div className="solutions__list-item-top">
                                            <h3 className="solutions__list-item-top-heading">
                                                {t('bkc.homepage.solution.b4e')}
                                            </h3>
                                            <span className="solutions__list-item-top-descriptions">
                                                {t('bkc.homepage.solution.b4e-description')}
                                            </span>
                                            <Link to="/b4e" className="solutions__list-item-top-text">
                                                {' '}
                                                {t('bkc.homepage.solution.more')} &rarr;
                                            </Link>
                                        </div>
                                        <img src={images.b4e} alt="b4e" className="solutions__list-item-img"></img>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="solutions__list-item solution-b4e">
                                        <div className="solutions__list-item-top">
                                            <h3 className="solutions__list-item-top-heading">
                                                {t('bkc.homepage.solution.eOffice')}
                                            </h3>
                                            <span className="solutions__list-item-top-descriptions">
                                                {t('bkc.homepage.solution.eOffice-description')}
                                            </span>
                                            <Link to="/eoffice" className="solutions__list-item-top-text">
                                                {' '}
                                                {t('bkc.homepage.solution.more')} &rarr;
                                            </Link>
                                        </div>
                                        <img
                                            src={images.eOffice}
                                            alt="eOffice"
                                            className="solutions__list-item-img"
                                        ></img>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="solutions__list-item solution-b4e">
                                        <div className="solutions__list-item-top">
                                            <h3 className="solutions__list-item-top-heading">
                                                {t('bkc.homepage.solution.bagri')}
                                            </h3>
                                            <span className="solutions__list-item-top-descriptions">
                                                {t('bkc.homepage.solution.bagri-description')}
                                            </span>
                                            <Link to="/bagri" className="solutions__list-item-top-text">
                                                {t('bkc.homepage.solution.more')} &rarr;
                                            </Link>
                                        </div>
                                        <img src={images.bagri} alt="bagri" className="solutions__list-item-img"></img>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="solutions__list-item solution-b4e">
                                        <div className="solutions__list-item-top">
                                            <h3 className="solutions__list-item-top-heading">
                                                {t('bkc.homepage.solution.vchain')}
                                            </h3>
                                            <span className="solutions__list-item-top-descriptions">
                                                {t('bkc.homepage.solution.vchain-description')}
                                            </span>
                                            <Link to="/vchain" className="solutions__list-item-top-text">
                                                {' '}
                                                {t('bkc.homepage.solution.more')} &rarr;
                                            </Link>
                                        </div>
                                        <img src={images.vchain} alt="b4e" className="solutions__list-item-img"></img>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="section" id="member_a">
                <div className="member">
                    <h3 className="member__heading"> {t('bkc.homepage.member.heading')}</h3>
                    <div className="member__list">
                        <div className="row medium-gutter">
                            {mainMembers.map((member) => {
                                return (
                                    <div className="col l-3  m-6 c-12">
                                        <div className="member__list-item">
                                            <div className="member__list-item-bg-img">
                                                <img
                                                    src={member.avatar}
                                                    alt="member"
                                                    className="member__list-item-img"
                                                ></img>
                                            </div>

                                            <div className="member__list-item-info">
                                                <h3 className="member__list-item-info-name">
                                                    {t('bkc.homepage.member.' + member.name)}
                                                </h3>
                                                <h4 className="member__list-item-info-position">
                                                    {t('bkc.homepage.member.' + member.position)}
                                                </h4>
                                                <h4 className="member__list-item-info-description">
                                                    {t('bkc.homepage.member.' + member.description)}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <Link smooth to="./members" className="member__list-btn">
                            <PrimaryButton> {t('bkc.homepage.member.more')}</PrimaryButton>
                        </Link>
                    </div>
                </div>
            </section>
            <section className="section" id="publications_a">
                <div className="publications">
                    <h2 className="publications__heading">{t('bkc.homepage.publications.heading')}</h2>
                    <div className="publications__list">
                        <div className="row big-gutter">
                            <div className="col l-6 m-12 c-12">
                                <PublicSmall>{publications[0]}</PublicSmall>
                            </div>
                            <div className="col l-6 m-12 c-12">
                                <Row>
                                    {publications.map((publication, index) => {
                                        if (index !== 0 && index !== 3) {
                                            return (
                                                <>
                                                    <PublicBig>{publication}</PublicBig>
                                                    <div className="publications__list-right-line"></div>
                                                </>
                                            );
                                        }
                                        if (index !== 0 && index === 3) {
                                            return (
                                                <>
                                                    <PublicBig>{publication}</PublicBig>
                                                </>
                                            );
                                        }
                                    })}
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="publications__btn">
                        <PrimaryButton>
                            <Link smooth style={{ textDecoration: 'none', color: '#fff' }} to="/publications">
                                {t('bkc.homepage.publication.btn')}
                            </Link>
                        </PrimaryButton>
                    </div>
                </div>
            </section> */}
        </div>
    );
};

export default Homepage;
