const images = {
    NBM_teacher: require('~/assets/images/mr-minh.png').default,
    DTC_teacher: require('~/assets/images/mr-chung.png').default,
    DBL_teacher: require('~/assets/images/mr-lam.png').default,
    dhn: require('~/assets/images/dhn.png').default,
    background: require('~/assets/images/background.png').default,
    b4e: require('~/assets/images/b4e.png').default,
    bagri: require('~/assets/images/bagri.png').default,
    bsign: require('~/assets/images/bsign.png').default,
    logo_bkc: require('~/assets/images/logo.png').default,
    pub1: require('~/assets/images/pub1.png').default,
    pub2: require('~/assets/images/pub2.png').default,
    pub3: require('~/assets/images/pub3.png').default,
    pub4: require('~/assets/images/pub4.png').default,
    vchain: require('~/assets/images/vchain.png').default,
    member_bg: require('~/assets/images/member_background.png').default,
    member_bg_fx: require('~/assets/images/member_background_fx.png').default,
    ttl: require('~/assets/images/ttl.png').default,
    ntha: require('~/assets/images/tnha.png').default,
    tmh: require('~/assets/images/tmh.png').default,
    nmd: require('~/assets/images/nmd.png').default,
    ltl: require('~/assets/images/ltl.png').default,
    nvq: require('~/assets/images/nvq.png').default,
    nvl: require('~/assets/images/nvl.png').default,
    pthq: require('~/assets/images/pthq.png').default,
    lhu: require('~/assets/images/lhu.png').default,
    tqd: require('~/assets/images/tqd.png').default,
    nvt: require('~/assets/images/nvt.png').default,
    vhh: require('~/assets/images/vhh.png').default,
    ntm: require('~/assets/images/ntm.png').default,
    dtt: require('~/assets/images/dtt.png').default,
    nsvh: require('~/assets/images/nsvh.png').default,
    ntt: require('~/assets/images/ntt.png').default,
    lab_background01: require('~/assets/images/lab_background1.png').default,
    lab_background02: require('~/assets/images/lab_background2.png').default,
    lab_background03: require('~/assets/images/lab_background3.png').default,
    lab_background04: require('~/assets/images/lab_background4.png').default,
    bsign1: require('~/assets/images/bsign1.png').default,
    bsign2: require('~/assets/images/bsign2.png').default,
    bsign3: require('~/assets/images/bsign3.png').default,
    bsign4: require('~/assets/images/bsign4.png').default,
    bsign5: require('~/assets/images/bsign5.png').default,
    bsign6: require('~/assets/images/bsign6.png').default,
    success: require('~/assets/images/success.png').default,
    vchain_page: require('~/assets/images/vchain-page.jpg').default,
    eOffice: require('~/assets/images/eOffice.png').default,
};

export default images;
