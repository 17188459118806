import PropTypes from 'prop-types';
import './Responsive.css';
import './Grid.css';

function Responsive({ children }) {
    return children;
}

Responsive.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Responsive;
