import 'antd/dist/antd.css';
import Footer from '../components/Footer';
import FooterChildPage from '../components/FooterChildPage';
import HeaderChildPage from '../components/HeaderChildPage';
import './ChildPageLayout.sass';

function AppLayout({ children, match }) {
    return (
        <>
            <HeaderChildPage />
            <div className="content">{children}</div>
            <FooterChildPage />
        </>
    );
}

export default AppLayout;
