import React, { useEffect, useRef, useState } from 'react';
import images from '~/assets/images/images';
import i18n from 'i18next';
import { I18LANGUAGE_KEY } from '~/app-configs';
import { useTranslation } from 'react-i18next';
import './Header.sass';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import Select from '../Select';
import { Menu } from '~/assets/svgs';
import { func } from 'prop-types';
import { set } from 'react-hook-form';

export default function (props) {
    const { t } = useTranslation();

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth',
            });
        });
    });

    const nav_wrapper = useRef(null);
    const nav = useRef(null);
    const stick1 = useRef(null);
    const stick2 = useRef(null);
    const [block, setBlock] = useState(false);
    const [active, setActive] = useState(false);
    const anchor_nav = useRef();

    console.log(anchor_nav);
    useEffect(() => {
        console.log('click', block);
        if (window.innerWidth < 1023) {
            if (block) {
                nav_wrapper.current.style.display = 'block';
                nav_wrapper.current.style.opacity = 1;
                // nav.current.style.transform = 'translateX(-100%)';
                nav.current.style.animation = '.5s ease 0s 1 normal none running aniDown';
            } else {
                // nav.current.style.animation = '1s ease 0s 1 normal none running aniUp';

                nav_wrapper.current.style.display = 'none';
            }
            if (active) {
                stick1.current.classList.add('active');
                stick2.current.classList.add('active');
            } else {
                stick1.current.classList.remove('active');
                stick2.current.classList.remove('active');
            }
        } else {
            nav_wrapper.current.style.display = 'block';
        }
    }, [block, screen.width]);

    function handleMenuClick() {
        console.log('click');
        console.log(block);
        console.log(window.innerWidth);
        if (block && window.innerWidth < 1023) {
            nav_wrapper.current.style.display = 'none';
            stick1.current.classList.remove('active');
            stick2.current.classList.remove('active');
            setActive(!active);
            setBlock(!block);
        }
    }
    return (
        <div>
            <section id="header-wrapper">
                <div className="header">
                    <div className="header__menu">
                        <div
                            onClick={() => {
                                console.log('click');
                                setBlock(!block);
                                setActive(!active);
                            }}
                            className="header__menu-menu"
                        >
                            {/* <Menu></Menu> */}
                            <div
                                style={{ position: 'relative', width: 24, height: 24 }}
                                className="header__menu-menu-test"
                            >
                                <div ref={stick1} className="header__menu-menu-test-stick1"></div>
                                <div ref={stick2} className="header__menu-menu-test-stick2"></div>
                            </div>
                        </div>
                        <Link to="/">
                            <img src={images.logo_bkc} alt="logo" className="header__menu-logo"></img>
                        </Link>
                        <span className="header__menu-bkc-text">BKC Labs</span>
                    </div>
                    <a href="/" className="header__bkc-logo">
                        <img src={images.logo_bkc} alt="logo" className="header__bkc-logo-logo"></img>
                        <span className="header__bkc-logo-bkc-text"> BKC Labs</span>
                    </a>
                    <div ref={nav_wrapper} className="header-nav-wrapper">
                        <div ref={nav} className="header__nav">
                            <a
                                onClick={() => handleMenuClick()}
                                ref={anchor_nav}
                                href="#introduction_a"
                                className="header__nav-item introduction_a"
                            >
                                {t('bkc.homepage.header-introduction')}
                            </a>
                            <a
                                onClick={() => handleMenuClick()}
                                ref={anchor_nav}
                                href="#solutions_a"
                                className="header__nav-item solutions_a"
                            >
                                {t('bkc.homepage.header-solution')}
                            </a>
                            {/* <a
                                onClick={() => handleMenuClick()}
                                ref={anchor_nav}
                                href="#member_a"
                                className="header__nav-item member_a"
                            >
                                {t('bkc.homepage.header-member')}
                            </a>
                            <a
                                onClick={() => handleMenuClick()}
                                ref={anchor_nav}
                                href="#publications_a"
                                className="header__nav-item publications_a"
                            >
                                {t('bkc.homepage.header-info')}
                            </a> */}
                            <a
                                onClick={() => handleMenuClick()}
                                ref={anchor_nav}
                                href="#footer_a"
                                className="header__nav-item footer_a"
                            >
                                {t('bkc.homepage.header-contact')}
                            </a>
                            <div className="header__nav-item header__nav-item-btn-mobile footer_a">
                                <Select optionList={['Tiếng Việt', 'English']}></Select>
                            </div>
                        </div>
                    </div>

                    <div className="header__select">
                        <Select optionList={['Tiếng Việt', 'English']}></Select>
                    </div>
                </div>
            </section>
        </div>
    );
}
