import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { I18LANGUAGE_KEY } from '~/app-configs';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import styles from './Select.sass';
import classnames from 'classnames/bind';
import { ArrowDown } from '~/assets/svgs';

const cx = classnames.bind(styles);

const Select = ({ optionList, setFilter = () => {} }) => {
    // console.log(optionList);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggling = () => setIsOpen(!isOpen);

    function handleChangeLanguage(value) {
        console.log('value:' + value);
        let lang = '';
        if (value === 'Tiếng Việt') {
            lang = 'vi';
            localStorage.setItem(I18LANGUAGE_KEY, lang);
            i18n.changeLanguage(lang);
        } else {
            lang = 'en';
            localStorage.setItem(I18LANGUAGE_KEY, lang);
            i18n.changeLanguage(lang);
        }
    }
    const onOptionClicked = (value) => () => {
        // handleFilter(value);
        if (value === 'Tiếng Việt' || value === 'English') {
            console.log('value' + value);
            setSelectedOption(value);
            setIsOpen(false);
            // console.log(selectedOption);
            handleChangeLanguage(value);
        } else {
            setSelectedOption(value);
            setIsOpen(false);
        }
    };

    useEffect(() => {
        setFilter({
            filter: selectedOption,
        });
    }, [selectedOption]);

    return (
        <div className={cx('select-wrapper')}>
            {/* <h1>Custom Select/dropdown</h1> */}
            <div className={cx('select')}>
                <div className={cx('select__header')} onClick={toggling}>
                    {optionList[0] === 'Tiếng Việt'
                        ? window.localStorage.i18nextLng === 'vi'
                            ? 'Tiếng Việt'
                            : 'English'
                        : selectedOption || optionList[0]}
                    <ArrowDown></ArrowDown>
                </div>

                {isOpen && (
                    <div>
                        <ul className={cx('select__list')} style={{ listStyle: 'none' }}>
                            {optionList.map((option) => (
                                <li
                                    className={cx('select__list-item')}
                                    onClick={onOptionClicked(option)}
                                    key={Math.random()}
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Select;
