import { exact } from 'prop-types';
import { lazy } from 'react';
import AppLayout from '~/components/Layout/AppLayout';
import ChildPageLayout from '~/components/Layout/ChildPageLayout';
export const loginModule = { key: 'homePage', path: 'HomePage' };

export default {
    path: '/',
    exact: true,
    isPrivate: false,
    layout: AppLayout,
    component: lazy(async () => {
        return import('./pages/Homepage');
    }),
};

export const childRoutes = [
    {
        path: '/members',
        exact: true,
        isPrivate: false,
        layout: ChildPageLayout,
        component: lazy(async () => {
            return import('./pages/Members');
        }),
    },

    {
        path: '/publications',
        exact: true,
        isPrivate: false,
        layout: ChildPageLayout,
        component: lazy(async () => {
            return import('./pages/Publications');
        }),
    },

    {
        path: '/bsign',
        exact: true,
        isPrivate: false,
        layout: ChildPageLayout,
        component: lazy(async () => {
            return import('./pages/Solutions');
        }),
    },
    {
        path: '/vchain',
        exact: true,
        isPrivate: false,
        layout: ChildPageLayout,
        component: lazy(async () => {
            return import('./pages/SolutionVChain');
        }),
    },
    {
        path: '/bagri',
        exact: true,
        isPrivate: false,
        layout: ChildPageLayout,
        component: lazy(async () => {
            return import('./pages/SolutionBAgri');
        }),
    },
    {
        path: '/b4e',
        exact: true,
        isPrivate: false,
        layout: ChildPageLayout,
        component: lazy(async () => {
            return import('./pages/SolutionB4E');
        }),
    },
    {
        path: '/eoffice',
        exact: true,
        isPrivate: false,
        layout: ChildPageLayout,
        component: lazy(async () => {
            return import('./pages/SolutionEoffice');
        }),
    },
    // {
    //     path: '/contact',
    //     exact: true,
    //     isPrivate: false,
    //     layout: null,
    //     component: lazy(async () => {
    //         return import('./pages/Contact');
    //     }),
    // },
];
