import React from 'react';
import './PublicBig.sass';

function PublicBig({ children }) {
    return (
        <div className="wrapper">
            <div className="publications__list-right">
                <img className="publications__list-right-img" src={children.img} alt="image"></img>
                <div className="publications__list-right-info">
                    <h3 className="publications__list-right-info-heading">{children.name}</h3>
                    <span className="publications__list-right-info-time">
                        {/* {children.public_time.format('YYYY-MM-DDTHH:mm:s')} */}
                        {children.public_time}
                    </span>
                    <span className="publications__list-right-info-description">{children.description}</span>
                </div>
            </div>
        </div>
    );
}

export default PublicBig;
