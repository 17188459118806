import { Carousel } from 'antd';
import React from 'react';
import images from '~/assets/images/images';
const contentStyle = {
    height: '778px',
    width: '100%',
    color: '#fff',
    lineHeight: '160px',
    // textAlign: 'center',
    background: '#364d79',
};

const Slider = () => (
    <Carousel dots={false} autoplay>
        <div className="image-slider" style={contentStyle}>
            {/* <h3 style={(contentStyle, { backgroundColor: 'red' })}> */}
            <img style={{ height: 778, width: '100%', objectFit: 'cover' }} src={images.lab_background01}></img>
        </div>
        {/*<div className="image-slider">
             <h3 style={contentStyle}> 
            <img style={{ height: 778, width: '100%', objectFit: 'cover' }} src={images.lab_background04}></img>
             </h3> 
        </div>*/}
    </Carousel>
);

export default Slider;
