import React from 'react';

export const DocumentIcon = () => {
    return (
        <svg
            cursor={'pointer'}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5H12C12.4142 7.5 12.75 7.83579 12.75 8.25C12.75 8.66421 12.4142 9 12 9H6C5.58579 9 5.25 8.66421 5.25 8.25Z"
                fill="#B1B5C4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 11.25C5.25 10.8358 5.58579 10.5 6 10.5H9C9.41421 10.5 9.75 10.8358 9.75 11.25C9.75 11.6642 9.41421 12 9 12H6C5.58579 12 5.25 11.6642 5.25 11.25Z"
                fill="#B1B5C4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.75 14.25C15.75 15.4926 14.7426 16.5 13.5 16.5H4.5C3.25736 16.5 2.25 15.4926 2.25 14.25V3.75C2.25 2.50736 3.25736 1.5 4.5 1.5H12L12.008 1.50803C12.5352 1.55269 13.0321 1.78207 13.409 2.15901L15.091 3.84099C15.4679 4.21793 15.6973 4.71484 15.742 5.24197L15.75 5.25V14.25ZM13.5 15H4.5C4.08579 15 3.75 14.6642 3.75 14.25V3.75C3.75 3.33579 4.08579 3 4.5 3H11.25V4.5C11.25 5.32843 11.9216 6 12.75 6H14.25V14.25C14.25 14.6642 13.9142 15 13.5 15Z"
                fill="#B1B5C4"
            />
        </svg>
    );
};

export const DownloadIcon = () => {
    return (
        <svg
            cursor={'pointer'}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.751 3.74951C9.751 3.3353 9.41521 2.99951 9.001 2.99951C8.58679 2.99951 8.251 3.3353 8.251 3.74951V9.81419L6.15631 7.71949C5.86341 7.4266 5.38854 7.4266 5.09565 7.71949C4.80275 8.01238 4.80275 8.48726 5.09565 8.78015L7.94035 11.6249C8.52613 12.2106 9.47588 12.2106 10.0617 11.6249L12.9049 8.78162C13.1978 8.48872 13.1978 8.01385 12.9049 7.72096C12.612 7.42806 12.1371 7.42806 11.8443 7.72096L9.751 9.81421V3.74951Z"
                fill="#B1B5C4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 10.5C3.41421 10.5 3.75 10.8358 3.75 11.25V12.75C3.75 13.1642 4.08579 13.5 4.5 13.5H13.5C13.9142 13.5 14.25 13.1642 14.25 12.75V11.25C14.25 10.8358 14.5858 10.5 15 10.5C15.4142 10.5 15.75 10.8358 15.75 11.25V12.75C15.75 13.9926 14.7426 15 13.5 15H4.5C3.25736 15 2.25 13.9926 2.25 12.75V11.25C2.25 10.8358 2.58579 10.5 3 10.5Z"
                fill="#B1B5C4"
            />
        </svg>
    );
};

export const SearchIcon = () => {
    return (
        <svg
            cursor={'pointer'}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.1792 12.2399C10.1632 13.0297 8.88653 13.5 7.5 13.5C4.18629 13.5 1.5 10.8137 1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5C10.8137 1.5 13.5 4.18629 13.5 7.5C13.5 8.88653 13.0297 10.1632 12.2399 11.1792L16.2803 15.2197C16.5732 15.5126 16.5732 15.9874 16.2803 16.2803C15.9874 16.5732 15.5126 16.5732 15.2197 16.2803L11.1792 12.2399ZM12 7.5C12 9.98528 9.98528 12 7.5 12C5.01472 12 3 9.98528 3 7.5C3 5.01472 5.01472 3 7.5 3C9.98528 3 12 5.01472 12 7.5Z"
                fill="#B1B5C4"
            />
        </svg>
    );
};

export const ShareIcon = () => {
    return (
        <svg
            cursor={'pointer'}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.87224 5.35156C10.0181 5.84513 10.2875 6.28567 10.6447 6.63739L8.12694 8.14803C7.98105 7.65447 7.71164 7.21392 7.35449 6.86221L9.87224 5.35156Z"
                fill="#B1B5C4"
            />
            <path
                d="M7.35449 11.1374L9.87222 12.648C10.0181 12.1545 10.2875 11.7139 10.6447 11.3622L8.12694 9.85156C7.98105 10.3451 7.71164 10.7857 7.35449 11.1374Z"
                fill="#B1B5C4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.75 6C13.5784 6 14.25 5.32843 14.25 4.5C14.25 3.67157 13.5784 3 12.75 3C11.9216 3 11.25 3.67157 11.25 4.5C11.25 5.32843 11.9216 6 12.75 6ZM15.75 4.5C15.75 6.15685 14.4069 7.5 12.75 7.5C11.0931 7.5 9.75 6.15685 9.75 4.5C9.75 2.84315 11.0931 1.5 12.75 1.5C14.4069 1.5 15.75 2.84315 15.75 4.5Z"
                fill="#B1B5C4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.75 15C13.5784 15 14.25 14.3284 14.25 13.5C14.25 12.6716 13.5784 12 12.75 12C11.9216 12 11.25 12.6716 11.25 13.5C11.25 14.3284 11.9216 15 12.75 15ZM15.75 13.5C15.75 15.1569 14.4069 16.5 12.75 16.5C11.0931 16.5 9.75 15.1569 9.75 13.5C9.75 11.8431 11.0931 10.5 12.75 10.5C14.4069 10.5 15.75 11.8431 15.75 13.5Z"
                fill="#B1B5C4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 10.5C6.07843 10.5 6.75 9.82843 6.75 9C6.75 8.17157 6.07843 7.5 5.25 7.5C4.42157 7.5 3.75 8.17157 3.75 9C3.75 9.82843 4.42157 10.5 5.25 10.5ZM8.25 9C8.25 10.6569 6.90685 12 5.25 12C3.59315 12 2.25 10.6569 2.25 9C2.25 7.34315 3.59315 6 5.25 6C6.90685 6 8.25 7.34315 8.25 9Z"
                fill="#B1B5C4"
            />
        </svg>
    );
};

export const PublicSearchIcon = () => {
    return (
        <svg
            className="publications__search-bar-icon"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            cursor={'pointer'}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0319 14.6177C17.2635 13.078 18 11.125 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C11.125 18 13.078 17.2635 14.6177 16.0319L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L16.0319 14.6177ZM16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9Z"
                fill="#9DA3B5"
            />
        </svg>
    );
};

export const ArrowDown = () => {
    return (
        <svg
            cursor={'pointer'}
            style={{ marginLeft: 8 }}
            width="15"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.0859 0.792894C8.69098 0.402369 8.0507 0.402369 7.65579 0.792894L4.83152 3.58579L2.00724 0.792893C1.61233 0.402369 0.972052 0.402369 0.57714 0.792893C0.182227 1.18342 0.182227 1.81658 0.57714 2.20711L4.11647 5.70711C4.51138 6.09763 5.15166 6.09763 5.54657 5.70711L9.0859 2.20711C9.48081 1.81658 9.48081 1.18342 9.0859 0.792894Z"
                fill="#353945"
            />
        </svg>
    );
};

export const Menu = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 16C4 15.4477 4.44772 15 5 15H19C19.5523 15 20 15.4477 20 16C20 16.5523 19.5523 17 19 17H5C4.44772 17 4 16.5523 4 16Z"
                fill="#23262F"
                className="menu-stick-1"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 8C4 7.44772 4.44772 7 5 7H19C19.5523 7 20 7.44772 20 8C20 8.55228 19.5523 9 19 9H5C4.44772 9 4 8.55228 4 8Z"
                fill="#23262F"
                className="menu-stick-2"
            />
        </svg>
    );
};

export const FailIcon = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 15C12.5523 15 13 14.5523 13 14V9.00001C13 8.44772 12.5523 8.00001 12 8.00001C11.4477 8.00001 11 8.44772 11 9.00001V14C11 14.5523 11.4477 15 12 15Z"
                    fill="#FF6A55"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.6737 17.5049L12.8688 5.56395C12.4851 4.89079 11.5147 4.89079 11.1311 5.56395L4.32615 17.5049C3.94624 18.1716 4.42767 19 5.19497 19H18.8049C19.5722 19 20.0537 18.1716 19.6737 17.5049ZM14.6064 4.5737C13.4555 2.55421 10.5443 2.55421 9.39347 4.5737L2.58851 16.5147C1.44877 18.5146 2.89306 21 5.19497 21H18.8049C21.1068 21 22.5511 18.5146 21.4114 16.5147L14.6064 4.5737Z"
                    fill="#FF6A55"
                />
                <path
                    d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
                    fill="#FF6A55"
                />
            </svg>
        </>
    );
};
