import React from 'react';
import './PublicSmall.sass';

function PublicSmall({ children }) {
    return (
        <div className="publications__list-left">
            <img className="publications__list-left-img" alt="publication" src={children.img}></img>
            <div className="publications__list-left-heading">
                <h3 className="publications__list-left-heading-title">{children.name}</h3>
                <span className="publications__list-left-heading-time">{children.public_time}</span>
            </div>
            <span className="publications__list-left-description">{children.description}</span>
        </div>
    );
}

export default PublicSmall;
