import 'antd/dist/antd.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import './AppLayout.sass';

function AppLayout({ children, match }) {
    return (
        <>
            <Header />
            <div className="content">{children}</div>
            <Footer />
        </>
    );
}

export default AppLayout;
